import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log("App is being served from cache by a service worker.\n" + "For more details, visit https://goo.gl/AFskqB");
    },
    registered() {
      console.log("registered");
    },
    cached() {
      console.log("cached");
    },
    updatefound() {
      console.log("updatefound");
    },
    updated() {
      console.log("updated");
      //アプリの更新があった際ブラウザをリロード
      window.location.reload();
    },
    offline() {
      console.log("offline");
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
