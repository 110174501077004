<template>
  <div>
    <video ref="videoElement" width="240" height="240" autoplay muted playsinline></video>
    <canvas ref="canvasElement" width="240" height="240" style="display: none"></canvas>
    <div v-if="isOnline">
      <p>現在インターネットに接続されています。</p>
    </div>
    <div v-else>
      <p>現在インターネットに接続されていません</p>
      <p>オンラインになった時に以下の合計値がデータベースに送信されます。</p>
      <p>{{ totalQrcode }}</p>
    </div>
    <button type="button" @click="initCamera">カメラを起動する</button>
    <p>更新確認42</p>
    {{ cameraStatus }}
  </div>
</template>
<style scoped>
video {
  object-fit: cover;
}

li {
  list-style: none;
}

.btn {
  display: block;
  margin: 0 auto;
}
</style>
<script>
import jsQR from "jsqr";
export default {
  data() {
    return {
      totalQrcode: 0,
      isOnline: navigator.onLine,
      deviceId: "",
      cameraStatus: "",
      setIntervalId: "",
    };
  },
  methods: {
    async getCameraStream() {
      try {
        // リアカメラがある場合
        this.cameraStatus = "リアカメラ";
        return await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: { exact: "environment" },
          },
        });
      } catch (e) {
        // リアカメラがない場合
        this.cameraStatus = "リアカメラ以外";
        return await navigator.mediaDevices.getUserMedia({
          video: true,
        });
      }
    },
    loadQrcode(canvas, video) {
      // canvasにvideoからフレームを描画
      canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
      // canvasのイメージデータを取得
      const imageData = canvas.getContext("2d").getImageData(0, 0, canvas.width, canvas.height);
      // jsQRを使用してQRコードを読み取る
      return jsQR(imageData.data, imageData.width, imageData.height);
    },
    postTotalQrcode() {
      alert("オフライン時に" + this.totalQrcode + "件QRコードが読み取りされました。");
    },
    plusQrcode() {
      this.totalQrcode++;
    },
    handleNetworkChange() {
      this.isOnline = navigator.onLine;
    },
    async initCamera() {
      const video = this.$refs.videoElement;
      const canvas = this.$refs.canvasElement;
      const stream = await this.getCameraStream();

      //デバイスデータが取得できない
      if (!stream.active) {
        this.cameraStatus = "該当するカメラはありません。";
        return;
      }

      video.srcObject = stream;
      video.onloadedmetadata = () => {
        //setIntervalが２重で発生するのを防ぐ
        if (this.setIntervalId) clearInterval(this.setIntervalId);
        // QRコードを読み取るためのsetIntervalを設定
        this.setIntervalId = setInterval(async () => {
          const qucode = await this.loadQrcode(canvas, video);
          if (qucode) {
            //合計値を追加
            this.plusQrcode();
            alert("読み取りが成功しました。");
          }
        }, 1000);
      };
    },
  },
  mounted() {
    window.addEventListener("online", this.handleNetworkChange);
    window.addEventListener("offline", this.handleNetworkChange);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.handleNetworkChange);
    window.removeEventListener("offline", this.handleNetworkChange);
    window.clearInterval(this.setIntervalId);
  },
  watch: {
    isOnline(newValue) {
      if (newValue) {
        //オフラインからオンラインへ切り替え時
        this.postTotalQrcode();
      } else {
        //オンラインからオフラインへ切り替え時
        //カウンターを初期化
        this.totalQrcode = 0;
      }
    },
  },
};
</script>
